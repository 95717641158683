import React, { useState, useRef, useEffect } from "react"
import Layout from "../components/layout"
import * as styles from "../css/cobie.module.css"
import API from "../services/api"
import axios from "axios"

const UploadCSV = () => {
  const [file, setFile] = useState("")

  const uploadButton = useRef(null)

  const onButtonClick = () => {
    uploadButton.current.click()
  }

  const handleChange = e => {
    const fileUploaded = e.target.files[0]
    setFile(fileUploaded)
    uploadFile(fileUploaded)
  }

  const uploadFile = async file => {
    const formData = new FormData()
    formData.append("file", file, file.name)
    const { data: uploadData } = await API.post("wp/v2/media", formData)
    console.log(uploadData)
    const params = {
      url: uploadData.source_url,
    }
    const { data: extractionOutput } = await axios.get(
      "http://127.0.0.1:5000/extract-text",
      { params }
    )
    console.log(extractionOutput)
  }

  return (
    <Layout>
      <div className={styles.container}>
        <input
          type="file"
          // accept=".csv"
          accept=".pdf"
          onChange={handleChange}
          style={{ display: "none" }}
          ref={uploadButton}
        />
        <button className={styles.uploadButton} onClick={onButtonClick}>
          Upload File
        </button>
      </div>
    </Layout>
  )
}

export default UploadCSV
